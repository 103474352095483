import { DEFAULT_LIMIT } from '@/api/helpers/queryHelpers';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS_ARRAY_FORMAT_OPTIONS } from './useReplaceSearchParams';

export type QueryParamsType = {
  page?: number | null;
  limit?: number | null;
  ordering?: string;
};

export const DEFAULT_QUERY_PARAMS: QueryParamsType = {
  page: 0,
  limit: DEFAULT_LIMIT,
};

export const useGetSearchParams = <T>(params?: QueryParamsType): QueryParamsType & T => {
  const [searchParams] = useSearchParams();

  try {
    const queryParams: QueryParamsType = queryString.parse(searchParams.toString(), {
      parseBooleans: true,
      ...SEARCH_PARAMS_ARRAY_FORMAT_OPTIONS,
      types: {
        page: 'number',
        limit: 'number',
      },
    });

    const { page: paramsPage, limit: paramsLimit, ...restParams } = queryParams;

    const defaultParams = { ...DEFAULT_QUERY_PARAMS, ...params };
    const page = paramsPage === undefined ? defaultParams.page : paramsPage;
    const limit = paramsLimit === undefined ? defaultParams.limit : paramsLimit;

    return {
      page,
      limit,
      ...(restParams as T),
    };
  } catch (error) {
    console.error('Parsing query params failed with', error?.message);
  }
};
