import { ApiParams, prepareBaseListApiParams } from '@/api/helpers/urlApiHelpers';
import { getBillingPlansApi } from '@/api/v1/opco/billing/getBillingPlansApi';
import { createBillingPlanApi, CreateBillingPlanType } from '@/api/v1/opco/billing/createBillingPlanApi';
import { getAuthTokenHeaders } from '@/helpers/apiHelpers/queryHelper';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getBaseBillingPlansApi } from '@/api/v1/opco/billing/getBaseBillingPlansApi';

export const loadBillingPlans = async (params: ApiParams) => {
  const apiParams = prepareBaseListApiParams(params);

  try {
    const response = await getBillingPlansApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createBillingPlan = async (payload: CreateBillingPlanType, pinToken: string) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const response = await createBillingPlanApi(payload, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadBaseBillingPlans = async () => {
  try {
    const response = await getBaseBillingPlansApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
