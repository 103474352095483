import { Box } from '@/components/Box/Box';
import { ReactComponent as CheckedIcon } from './images/CheckedIcon.svg';
import { ReactComponent as UnCheckedIcon } from './images/UncheckedIcon.svg';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { ReactNode } from 'react';

type BillingPlanCardDetailsRowType = {
  isActive?: boolean;
  children: ReactNode;
};

export const BillingPlanCardDetailsRow = ({ children, isActive = true }: BillingPlanCardDetailsRowType) => {
  return (
    <Box display='flex' alignItems='flex-start' columnGap={8}>
      <Box flexShrink={0}>{isActive ? <CheckedIcon /> : <UnCheckedIcon />}</Box>
      <Typography flexGrow={1} variant='bodySmall' color={COLORS.black}>
        {children}
      </Typography>
    </Box>
  );
};
