import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { BaseBillingPlanAddonOrDiscountType } from '@/types/billingTypes';

export const formatLimits = (limit: number | null, postfix: string = '') => {
  return limit === null ? 'Unlimited' : `${limit} ${postfix}`.trim();
};

export const formatAddonOrDiscountAmount = (addonOrDiscount: BaseBillingPlanAddonOrDiscountType) => {
  if (!addonOrDiscount) return '';

  const { amount, percentage } = addonOrDiscount;

  if (amount !== null) {
    return convertCentsToDollars(amount);
  }

  // Note: pepper pay format percentage value adding thousand after integer value
  if (percentage !== null) {
    return `${percentage / 1000}%`;
  }

  return '';
};
