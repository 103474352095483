import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { styled } from 'styled-components';

export const BillingCardWrapper = styled.div`
  ${flex({ flexDirection: 'column' })}
  padding: 20px;
  border-radius: 16px;
  border: 1px solid ${COLORS.grey[300]};
  width: 384px;
  background-color: ${COLORS.white};

  ${media.mobile`
    width: 100%
  `}
`;
