import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { flex } from '@/styles/layout';
import { bodySmall, caption, poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';
import { BillingFrequencyResponseType } from '@/types/paymentTypes';
import { PAYMENT_FREQUENCY_DB } from '@/const/payment';

export const FrequencyToggleWrapper = styled.div`
  width: 320px;
  height: 52px;
  padding: 6px;
  ${flex({ alignItems: 'center' })};
  border: 1px solid ${COLORS.grey[300]};
  border-radius: 12px;
  background-color: ${COLORS.white};
`;

export const ToggleWrapper = styled.div`
  ${flex()};
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CoveringBlock = styled.div<{ frequency: BillingFrequencyResponseType }>`
  position: absolute;
  width: 50%;
  height: 40px;
  background: ${COLORS.grey[200]};
  border-radius: 8px;
  transform: translate(0);
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;

  ${({ frequency }) =>
    frequency === PAYMENT_FREQUENCY_DB.MONTHLY &&
    `
    transform: translateX(100%);
  `};
`;

export const FrequencyButton = styled.button<{ active: boolean }>`
  ${flex({ alignItems: 'center', justifyContent: 'center' })}
  ${bodySmall};
  ${poppinsSemiBoldFont};
  color: ${({ active }) => (active ? `${COLORS.black}` : `${COLORS.grey[650]}`)};
  position: relative;
  width: 50%;
  z-index: 1;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  ${resetButton};
  cursor: pointer;
  column-gap: 8px;
`;

export const DiscountBadge = styled.span`
  width: max-content;
  padding: 4px 8px;
  background: ${COLORS.purple[100]};
  border-radius: 22px;
  border: 1px solid ${COLORS.purple[200]};
  ${caption};
  color: ${COLORS.purple[500]};
`;
