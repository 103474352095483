import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useResponsiveDevice } from '@/hooks/useResponsiveDevice';
import { HeaderWrapper, SideAction, Wrapper } from './styles';
import { loadPaymentPlans } from '../SettingsPage/api';
import { BillingFrequencyToggle } from './ui/BillingFrequencyToggle/BillingFrequencyToggle';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { BackButton, BackIcon } from '@/bundle/Layouts/ContentLayout/ContentHeader/styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { getSettingsUrl } from '../SettingsPage/urls/getSettingsUrl';
import { Button } from '@/components/Button/Button';
import { useLogout } from '@/bundle/shared/hooks/useLogout';
import { BillingPlanCard, SelectedAddonsType } from '@/bundle/shared/components/BillingPlanCard/BillingPlanCard';
import { BillingFrequencyResponseType } from '@/types/paymentTypes';
import { PAYMENT_FREQUENCY_DB } from '@/const/payment';
import { getPaymentCheckoutUrl } from '@/bundle/pages/BillingCheckoutPage/urls/getPaymentCheckoutUrl';
import { BILLING_PLAN_STATUS } from '@/const/billing';
import { BillingPlanType } from '@/types/billingTypes';

export const SelectBillingPlanPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isMobile } = useResponsiveDevice();
  const { mutate: logoutMutate } = useLogout();

  const [frequency, setFrequency] = useState<BillingFrequencyResponseType>(PAYMENT_FREQUENCY_DB.ANNUAL);

  // TODO v16.0.0 rename to billing
  const { data, isFetching } = useQuery({
    queryKey: ['load_payment_plans'],
    queryFn: () => loadPaymentPlans(),
  });

  const navigateToSettings = () => {
    navigate(getSettingsUrl());
  };

  const activePlans = data?.body?.results?.filter((billingPlan) => billingPlan?.status === BILLING_PLAN_STATUS.ACTIVE);
  const selectedFrequencyPlans = activePlans?.filter(
    (activePlan) => activePlan?.payment_service_plan_data?.billing_frequency === frequency
  );

  const redirectedFrom = searchParams.get('redirected_from');
  const planId = searchParams.get('plan_id');
  const isRedirectedFromApp = redirectedFrom === 'app';
  const isRedirectedFromAuth = redirectedFrom === 'auth';

  const onSelectPlan = (plan: BillingPlanType, addons: SelectedAddonsType) => {
    const addonsKeys = Object.keys(addons);

    navigate({
      pathname: getPaymentCheckoutUrl(),
      search: createSearchParams({
        plan_id: plan?.id,
        ...(!!addonsKeys.length && {
          addon_id: addonsKeys.join(','),
        }),
      }).toString(),
    });
  };

  return (
    <AuthLayout isLoading={isFetching} position='top'>
      <Wrapper>
        <Box width='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center' rowGap={42}>
          <Box width='100%' display='flex' flexDirection='column' alignItems='center' rowGap={24}>
            <Box width='100%' display='flex' flexDirection='column' alignItems='center' rowGap={isMobile ? 0 : 8}>
              <HeaderWrapper>
                <SideAction isVisible={isRedirectedFromApp}>
                  <BackButton data-tooltip-id='back-tooltip' onClick={navigateToSettings}>
                    <BackIcon />
                  </BackButton>
                </SideAction>

                <Typography variant={isMobile ? 'headline4' : 'headline'} fontWeight='semibold' textAlign='center'>
                  Find the plan for you
                </Typography>

                <SideAction isVisible={isRedirectedFromAuth}>
                  <Button size='medium' variant='secondary' width={84} onClick={logoutMutate}>
                    Logout
                  </Button>
                </SideAction>
              </HeaderWrapper>

              <Typography variant='subTitleSmall' fontWeight='medium' color={COLORS.grey[650]}>
                You can change it anytime.
              </Typography>
            </Box>
            {!!selectedFrequencyPlans?.length && (
              <BillingFrequencyToggle onChange={setFrequency} frequency={frequency} />
            )}
          </Box>
          <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} columnGap={24} rowGap={24}>
            {selectedFrequencyPlans?.map((selectedFrequencyPlan) => {
              const isSelectedPlan = planId === selectedFrequencyPlan?.id;

              return (
                <BillingPlanCard
                  plan={selectedFrequencyPlan}
                  key={selectedFrequencyPlan?.id}
                  onSelectPlan={onSelectPlan}
                  isSelectedPlan={isSelectedPlan}
                />
              );
            })}
          </Box>
        </Box>
      </Wrapper>
      <Tooltip id='back-tooltip'>Back to Settings</Tooltip>
    </AuthLayout>
  );
};
