import { BaseBillingPlanType } from '@/types/billingTypes';

export const getBillingPlanTotalAddonsAmount = (
  plan: BaseBillingPlanType,
  selectedAddons: { [key: string]: boolean }
) => {
  const planAmount = plan.amount;
  const addons = plan.add_ons;

  if (!addons) {
    return 0;
  }

  const addonsAmount = addons.reduce((result, addon) => {
    if (!selectedAddons[addon.id]) {
      return result;
    }

    const { amount, percentage } = addon;

    if (amount) {
      result += amount;
    }

    if (percentage) {
      const percent = percentage / 1000;

      result += Number(((planAmount / 100) * percent).toFixed(2));
    }

    return result;
  }, 0);

  return addonsAmount;
};
