import { OptionType, Select, SelectType } from '@/components/form/fields/Select/Select';
import { MultiSelectValue } from './ui/MultiSelectValue';
import { ClearIndicator } from './ui/ClearIndicator';
import { FormatOptionLabelMeta } from 'react-select';
import { Checkbox, CheckboxClickEventType } from '@/components/form/fields/Checkbox/Checkbox';
import { MultiSelectOptionWrapper } from './styles';
import { OptionLabel } from '@/components/form/fields/Select/styles';
import noop from 'lodash/noop';

export type MultiSelectType = SelectType & {
  placeholderValue?: string;
  value?: OptionType[];
};

const formatOptionLabel = (option: OptionType, meta: FormatOptionLabelMeta<OptionType>) => {
  const isMenuItem = meta.context === 'value';
  const isSelected = !!meta?.selectValue.find((item) => option.value === item?.value);

  const toggleCheckbox = (event: CheckboxClickEventType) => {
    event.stopPropagation();
  };

  return (
    <MultiSelectOptionWrapper>
      {!isMenuItem && <Checkbox checked={isSelected} onChange={noop} onClick={toggleCheckbox}></Checkbox>}
      <OptionLabel>{option.label}</OptionLabel>
    </MultiSelectOptionWrapper>
  );
};

export const MultiSelect = ({ placeholderValue, ...props }: MultiSelectType) => {
  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      components={{
        MultiValue: (multiValueProps) => <MultiSelectValue {...multiValueProps} placeholderValue={placeholderValue} />,
        ClearIndicator,
      }}
      formatOptionLabel={formatOptionLabel}
      {...props}
    />
  );
};
