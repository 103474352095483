import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { ReactComponent as PepperPayIcon } from './images/PepperPayIcon.svg';
import {
  BillingPlanDetailsIconWrapper,
  BillingPlanDetailsInfoRow,
  BillingPlanDetailsInfoItem,
  BillingPlanDetailsInfoDivider,
} from '../../styles';
import { InfoTooltipBadge } from '@/bundle/shared/components/InfoTooltipBadge/InfoTooltipBadge';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import capitalize from 'lodash/capitalize';
import { BillingPlanType } from '@/types/billingTypes';

type BillingBasePlanDetailsInfoType = {
  plan: BillingPlanType;
};

export const BillingBasePlanDetailsInfo = ({ plan }: BillingBasePlanDetailsInfoType) => {
  const { payment_service_plan_data } = plan;
  const { id, name, amount, billing_frequency } = payment_service_plan_data;

  return (
    <Box mb='24px'>
      <Box width='100%' display='flex' columnGap='16px' alignItems='center' mb='16px'>
        <BillingPlanDetailsIconWrapper>
          <PepperPayIcon />
        </BillingPlanDetailsIconWrapper>
        <Box>
          <Typography variant='bodySmall' color={COLORS.grey[650]}>
            Plan name
          </Typography>
          <Box display='flex' columnGap='12px'>
            <Typography variant='subTitle' fontWeight='semibold'>
              {name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mb='24px'>
        <Box mb='4px' display='flex' alignItems='center' columnGap='4px'>
          <Typography variant='bodySmall' color={COLORS.grey[650]}>
            Pepper Pay Plan ID
          </Typography>
          <InfoTooltipBadge>For more info, check out Pepper Pay.</InfoTooltipBadge>
        </Box>

        <Typography variant='bodySmall' fontWeight='medium' mb='16px'>
          {id}
        </Typography>
      </Box>

      <BillingPlanDetailsInfoRow>
        <BillingPlanDetailsInfoItem>
          <Typography variant='bodySmall' color={COLORS.grey[650]} mb='4px'>
            Amount
          </Typography>
          <Typography variant='bodySmall' fontWeight='medium'>
            {convertCentsToDollars(amount)}
          </Typography>
        </BillingPlanDetailsInfoItem>
        <BillingPlanDetailsInfoDivider />
        <BillingPlanDetailsInfoItem>
          <Typography
            display='flex'
            columnGap='4px'
            alignItems='center'
            variant='bodySmall'
            color={COLORS.grey[650]}
            mb='4px'
          >
            Billing Cycle
          </Typography>
          <Typography variant='bodySmall' fontWeight='medium'>
            {capitalize(billing_frequency)}
          </Typography>
        </BillingPlanDetailsInfoItem>
      </BillingPlanDetailsInfoRow>
    </Box>
  );
};
