import { DiscountBadge, FrequencyButton, FrequencyToggleWrapper, ToggleWrapper, CoveringBlock } from './styles';
import { BillingFrequencyResponseType } from '@/types/paymentTypes';
import { PAYMENT_FREQUENCY_DB } from '@/const/payment';

type BillingFrequencyToggleType = {
  frequency: BillingFrequencyResponseType;
  onChange: (frequency: BillingFrequencyResponseType) => void;
};

export const BillingFrequencyToggle = ({ frequency, onChange }: BillingFrequencyToggleType) => {
  const setYearlyFrequency = () => onChange(PAYMENT_FREQUENCY_DB.ANNUAL);
  const setMonthlyFrequency = () => onChange(PAYMENT_FREQUENCY_DB.MONTHLY);

  const isYearlyFrequency = frequency === PAYMENT_FREQUENCY_DB.ANNUAL;
  const isMonthlyFrequency = frequency === PAYMENT_FREQUENCY_DB.MONTHLY;

  return (
    <FrequencyToggleWrapper>
      <ToggleWrapper>
        <CoveringBlock frequency={frequency} />
        <FrequencyButton onClick={setYearlyFrequency} active={isYearlyFrequency}>
          Yearly
          <DiscountBadge>Save 10%</DiscountBadge>
        </FrequencyButton>
        <FrequencyButton onClick={setMonthlyFrequency} active={isMonthlyFrequency}>
          Monthly
        </FrequencyButton>
      </ToggleWrapper>
    </FrequencyToggleWrapper>
  );
};
