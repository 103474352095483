import { useField } from 'formik';
import { OptionType } from '@/components/form/fields/Select/Select';
import { MultiSelect, MultiSelectType } from '../MultiSelect/MultiSelect';

type FormikMultiSelectType = {
  name: string;
} & MultiSelectType;

export const FormikMultiSelect = ({ name, ...props }: FormikMultiSelectType) => {
  const [field, meta, { setValue }] = useField(name);

  const onChange = (value: OptionType) => {
    setValue(value);
  };

  return <MultiSelect {...field} onChange={onChange} value={meta?.value} {...props} />;
};
