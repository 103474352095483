import { createSearchParams } from 'react-router-dom';
import { BillingPlanSummaryWrapper, ContentWrapper, MonthPriceWrapper, SavingPriceWrapper } from './styles';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { getSelectBillingPlanUrl } from '@/bundle/pages/SelectBillingPlanPage/urls/getSelectBillingPlanUrl';
import { PAYMENT_FREQUENCY_DB } from '@/const/payment';
import capitalize from 'lodash/capitalize';
import { BillingPlanType } from '@/types/billingTypes';
import { BillingPlanCardDetails } from '@/bundle/shared/components/BillingPlanCard/ui/BillingPlanCardDetails/BillingPlanCardDetails';

type PaymentPlanSummaryType = {
  plan: BillingPlanType;
};

export const getTotalPrice = (amount: number, basePlanAddons: any[]) => {
  const totalAddonsAmount = basePlanAddons?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.amount,
    0
  );

  return amount + (totalAddonsAmount || 0);
};

export const BillingPlanSummary = ({ plan }: PaymentPlanSummaryType) => {
  if (!plan) return;

  const isYearlyFrequency = plan?.payment_service_plan_data?.billing_frequency === PAYMENT_FREQUENCY_DB.ANNUAL;
  const discount = plan?.payment_service_plan_data?.total_discounts_amount ?? 0;
  const priceWithDiscount = plan?.payment_service_plan_data?.amount - discount;

  const totalPrice = getTotalPrice(priceWithDiscount, plan?.payment_service_plan_data?.add_ons);

  const navigateToPaymentPlans = () => {
    return {
      pathname: getSelectBillingPlanUrl(),
      search: createSearchParams({
        redirected_from: 'auth',
      }).toString(),
    };
  };

  return (
    <BillingPlanSummaryWrapper>
      <ContentWrapper>
        <Box display='flex' alignItems='center' justifyContent='space-between' width='100%' mb='12px'>
          <Typography fontWeight='semibold' variant='subTitleSmall' color={COLORS.grey[950]}>
            {capitalize(plan?.name)} Plan
          </Typography>
          <NavigationLink to={navigateToPaymentPlans()} variant='primary'>
            <Typography fontWeight='medium' variant='bodySmall'>
              View plans
            </Typography>
          </NavigationLink>
        </Box>
        <Typography fontWeight='semibold' variant='bodySmall' color={COLORS.black}>
          Great choice. Here’s what your plan includes:
        </Typography>

        <BillingPlanCardDetails plan={plan} />

        <MonthPriceWrapper>
          <Typography variant='bodySmall'>Price / month</Typography>
          <Typography variant='bodySmall' fontWeight='semibold'>
            {convertCentsToDollars(priceWithDiscount)}
          </Typography>
        </MonthPriceWrapper>
        <Box display='flex' justifyContent='space-between' alignItems='center' mt='12px'>
          <Typography variant='bodySmall' fontWeight='semibold' color={COLORS.black}>
            Total
          </Typography>
          <Typography variant='headline4' fontWeight='semibold' color={COLORS.black}>
            {convertCentsToDollars(totalPrice)}
          </Typography>
        </Box>
      </ContentWrapper>
      {isYearlyFrequency && (
        <SavingPriceWrapper>
          <Typography variant='bodySmall' color={COLORS.black}>
            Annual Savings
          </Typography>
          <Typography variant='bodySmall' fontWeight='semibold' color={COLORS.green[500]}>
            10%
          </Typography>
        </SavingPriceWrapper>
      )}
    </BillingPlanSummaryWrapper>
  );
};
