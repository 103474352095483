import { COLORS } from '@/styles/colors';
import { dashedLine } from '@/styles/common';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsSemiBoldFont } from '@/styles/typography';
import { styled } from 'styled-components';

export const TotalAmountWrapper = styled.div`
  ${flex({ flexDirection: 'column' })}
  background-color: ${COLORS.grey[200]};
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const DiscountAmount = styled.span`
  text-decoration: line-through;
  color: ${COLORS.grey[650]};
  ${bodySmall};
  ${poppinsSemiBoldFont};
`;

export const Divider = styled.div`
  ${dashedLine};
`;
