import { COLORS } from '@/styles/colors';
import styled from 'styled-components';
import { flex } from '@/styles/layout';

export const BillingPlanPreviewWrapper = styled.div<{ isOpen: boolean }>`
  ${flex({ flexDirection: 'column' })}
  padding: 36px 24px;
  background: ${COLORS.grey[150]};
  overflow: hidden;
  border-radius: 20px 0 0 20px;
  position: relative;
  transition: width 500ms ease-in;

  ${({ isOpen }) =>
    isOpen &&
    `
     width: 432px
  `};
`;
