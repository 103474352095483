// =========== [WireVault API Swagger documentation] ===========
// https://admin.dev.wirevault-beta.com/swagger/

export const HTTP_SUCCESS_STATUS = 200;
export const HTTP_UNAUTHORIZED_STATUS = 401;
export const HTTP_FORBIDDEN_STATUS = 403;
export const HTTP_TOO_MANY_REQUESTS_STATUS = 429;

export const TEXT_HTML_CONTENT_TYPE = 'text/html; charset=utf-8';
export const APPLICATION_JSON_CONTENT_TYPE = 'application/json';
export const APPLICATION_MS_EXCEL_CONTENT_TYPE = 'application/vnd.ms-excel';

export const INVALID_TOKEN_RESPONSE = 'Token is invalid or expired';
export const BLOCKED_USER_RESPONSE = 'You don`t have access to this application.';
export const ACCESS_DENIED_RESPONSE = 'User has no rights to perform this action';
export const ACTIVATION_TOKEN_INVALID_RESPONSE = 'Activation token is invalid';
export const EXPIRED_TOKEN_CODE = 'token_not_valid';

export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const DEFAULT_LANGUAGE = 'en';
