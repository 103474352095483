import { BaseBillingPlanAddonOrDiscountType } from '@/types/billingTypes';
import { TableConfigItemsListType } from '@/components/Table/types/types';
import { formatAddonOrDiscountAmount } from '../../../helpers';

export const BILLING_PLAN_ADDONS_CONFIG: TableConfigItemsListType<BaseBillingPlanAddonOrDiscountType> = [
  {
    key: 1,
    name: 'name,-date_created',
    header: 'ADD-ON NAME',
    width: '30%',
    hasSort: false,
    getValue(item) {
      return item.name;
    },
  },
  {
    key: 2,
    name: 'description,',
    header: 'DESCRIPTION',
    width: '40%',
    hasSort: false,
    getValue(item) {
      return item.description || '-';
    },
  },
  {
    key: 3,
    name: 'value,',
    header: 'VALUE',
    width: '20%',
    hasSort: false,
    textAlign: 'right',
    getValue(item) {
      return formatAddonOrDiscountAmount(item);
    },
  },
];
