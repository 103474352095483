import ReactSelect, { FormatOptionLabelMeta, GroupBase, PropsValue, SelectComponentsConfig } from 'react-select';
import { customStyles, OptionLabel, OptionWrapper, SelectedIconWrapper, Wrapper } from './styles';
import { ReactNode } from 'react';
import { LoadingIndicator } from './ui/LoadingIndicator';
import { NoOptionsMessage } from './ui/NoOptionMessage';
import { SelectLabel } from './ui/SelectLabel';
import { InputError } from '../InputError/InputError';
import { ReactComponent as SelectedIcon } from './images/SelectedIcon.svg';

export interface OptionType {
  value: string | number;
  label: string;
  isDisabled?: boolean;
}

export type SelectType = {
  defaultOption?: OptionType;
  options: OptionType[];
  placeholder?: string;
  label?: string;
  value?: PropsValue<OptionType>;
  onChange?: (value: unknown) => void;
  onBlur?: (event) => void;
  error?: string;
  apiError?: string;
  isSupportError?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  postfixLabel?: ReactNode;
  disable?: boolean;
  emptyOptionPlaceholder?: string;
  hasPortal?: boolean;
  variant?: 'standart' | 'compact';
  size?: 'medium' | 'small';
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  components?: SelectComponentsConfig<OptionType, boolean, GroupBase<OptionType>>;
  formatOptionLabel?: (option: OptionType, meta: FormatOptionLabelMeta<OptionType>) => ReactNode;
};

const defaultFormatOptionLabel = (option: OptionType, meta: FormatOptionLabelMeta<OptionType>) => {
  const isMenuItem = meta.context === 'value';
  const isSelected = !!meta?.selectValue.find((item) => option.value === item?.value);
  const hasIcon = isSelected && !isMenuItem;

  return (
    <OptionWrapper>
      <OptionLabel>{option.label}</OptionLabel>
      {hasIcon && (
        <SelectedIconWrapper>
          <SelectedIcon />
        </SelectedIconWrapper>
      )}
    </OptionWrapper>
  );
};

export const Select = ({
  placeholder,
  options,
  label,
  onChange,
  onBlur,
  error,
  apiError,
  isSupportError = true,
  isSearchable = false,
  isLoading,
  postfixLabel,
  disable,
  emptyOptionPlaceholder = 'No options available',
  defaultOption,
  value,
  hasPortal = true,
  variant = 'standart',
  size = 'medium',
  isMulti = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = false,
  components,
  formatOptionLabel = defaultFormatOptionLabel,
}: SelectType) => {
  return (
    <Wrapper>
      <SelectLabel label={label} postfixLabel={postfixLabel} />
      <ReactSelect
        value={value}
        defaultValue={defaultOption}
        menuPlacement='auto'
        minMenuHeight={400}
        styles={customStyles({ error, hasPortal, variant, size })}
        placeholder={placeholder}
        options={options}
        menuPortalTarget={hasPortal ? document.getElementById('select') : null}
        isSearchable={isSearchable}
        onChange={(newValue) => onChange(newValue)}
        onBlur={onBlur}
        isDisabled={disable}
        isLoading={isLoading}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={() => <NoOptionsMessage message={emptyOptionPlaceholder} />}
        components={{
          LoadingIndicator,
          ...components,
        }}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
      />
      {isSupportError && <InputError error={error} apiError={apiError} />}
    </Wrapper>
  );
};
