import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';
import { BillingPlanType } from '@/types/billingTypes';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';

type BillingPlanDetailsInfoType = {
  plan: BillingPlanType;
};

export const BillingPlanDetailsInfo = ({ plan }: BillingPlanDetailsInfoType) => {
  if (!plan) return;

  const { id, description, date_created } = plan;

  return (
    <Box mb='24px'>
      <FieldRow columns={[3, 9]}>
        <Box>
          <Typography variant='bodySmall' color={COLORS.grey[650]} mb='4px'>
            Date created
          </Typography>
          <Typography variant='bodySmall' fontWeight='medium' mb='16px'>
            {formatDate(date_created)}
          </Typography>
        </Box>
        <Box>
          <Typography variant='bodySmall' color={COLORS.grey[650]} mb='4px'>
            WireVault Plan ID
          </Typography>
          <Typography variant='bodySmall' fontWeight='medium' mb='16px'>
            {id}
          </Typography>
        </Box>
      </FieldRow>

      <Typography variant='bodySmall' color={COLORS.grey[650]} mb='4px'>
        Description
      </Typography>
      <Typography variant='bodySmall'>{description || '-'}</Typography>
    </Box>
  );
};
