import { Box } from '@/components/Box/Box';
import { BillingPlanDetailsInfoDivider, BillingPlanDetailsInfoItem, BillingPlanDetailsInfoRow } from '../../styles';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { BillingPlanType } from '@/types/billingTypes';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { formatLimits } from '../../../helpers';

type BillingPlanDetailsLimitsType = {
  plan: BillingPlanType;
};

export const BillingPlanDetailsLimits = ({ plan }: BillingPlanDetailsLimitsType) => {
  const { wires_limit, users_limit, overage_price } = plan;
  const overagePrice = overage_price ? `${convertCentsToDollars(overage_price)} per wire` : '-';
  const wiresLimits = formatLimits(wires_limit, 'per month');
  const usersLimits = formatLimits(users_limit, 'user(s)');

  return (
    <Box mb='42px'>
      <Typography fontWeight='semibold' mb='8px'>
        Plan Limits
      </Typography>
      <Box display='flex' columnGap='16px' width='100%'>
        <BillingPlanDetailsInfoRow>
          <BillingPlanDetailsInfoItem>
            <Typography variant='bodySmall' color={COLORS.grey[650]} mb='4px'>
              Wires Limit
            </Typography>
            <Typography variant='bodySmall' fontWeight='medium'>
              {wiresLimits}
            </Typography>
          </BillingPlanDetailsInfoItem>
          <BillingPlanDetailsInfoDivider />
          <BillingPlanDetailsInfoItem>
            <Typography variant='bodySmall' color={COLORS.grey[650]} mb='4px'>
              Overage
            </Typography>
            <Typography variant='bodySmall' fontWeight='medium'>
              {overagePrice}
            </Typography>
          </BillingPlanDetailsInfoItem>
        </BillingPlanDetailsInfoRow>

        <BillingPlanDetailsInfoRow>
          <BillingPlanDetailsInfoItem>
            <Typography variant='bodySmall' color={COLORS.grey[650]} mb='4px'>
              Users Limit
            </Typography>
            <Typography variant='bodySmall' fontWeight='medium'>
              {usersLimits}
            </Typography>
          </BillingPlanDetailsInfoItem>
        </BillingPlanDetailsInfoRow>
      </Box>
    </Box>
  );
};
