import { authApiService } from '@/api/authApiService';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { BillingPlanType } from '@/types/billingTypes';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

export const getBillingPlansApi = async (
  params: ApiParams
): Promise<ApiResponseType<ListResponseType<BillingPlanType>>> => {
  const uri = '/opco/payments/plans/';

  return authApiService.GET(uri, params);
};
