import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { FieldRow } from '@/components/form/FieldRow/FieldRow';
import { Input } from '@/components/form/fields/Input/Input';
import { PlanExtensions } from './ui/PlanExtensions';
import { DrawerHeaderBackLine } from '../styles';
import { COLORS } from '@/styles/colors';
import { FormikSelect } from '@/components/form/fields/FormikSelect/FormikSelect';
import { useEffect } from 'react';
import { OptionType } from '@/components/form/fields/Select/Select';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { useField } from 'formik';
import { sortDisabledOptions } from './helpers';
import { BaseBillingPlanType, BillingPlanType } from '@/types/billingTypes';

type SelectBasePlanFormSectionType = {
  billingPlans: BillingPlanType[];
  baseBillingPlans: BaseBillingPlanType[];
  basePlan: BaseBillingPlanType;
  onSelectBasePlan: (basePlan: BaseBillingPlanType) => void;
};

export const SelectBasePlanFormSection = ({
  billingPlans,
  baseBillingPlans,
  basePlan,
  onSelectBasePlan,
}: SelectBasePlanFormSectionType) => {
  const [field] = useField('basePlan');
  const basePlanSelectOptions: OptionType[] = baseBillingPlans.map((plan) => ({
    value: plan.id,
    label: plan.name,
    isDisabled: billingPlans.some((billingPlan) => billingPlan.payment_service_id === plan.id),
  }));

  useEffect(() => {
    if (!field.value) {
      return;
    }

    const basePlanDetails = baseBillingPlans.find((plan) => plan.id === field.value.value);

    onSelectBasePlan(basePlanDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, baseBillingPlans]);

  return (
    <Box display='flex' flexDirection='column' mb='12px'>
      <DrawerHeaderBackLine>
        <Typography variant='subTitleSmall' fontWeight='medium'>
          Pepper Pay Plan
        </Typography>
      </DrawerHeaderBackLine>
      <Typography mt='20px' mb='16px' color={COLORS.grey[650]} variant='bodySmall'>
        Discover more details about the plan on the Pepper Pay page.
      </Typography>

      <FormikSelect
        name='basePlan'
        label='Pepper Pay Plan*'
        placeholder='Select Pepper Pay Plan'
        options={sortDisabledOptions(basePlanSelectOptions)}
      />

      {basePlan && (
        <Box>
          <Input value={basePlan.id} label='Plan ID' disabled />

          <FieldRow columns={[6, 6]}>
            <Input value={convertCentsToDollars(basePlan.amount)} label='Amount' disabled />
            <Input value={basePlan.billing_frequency} label='Billing Frequency' disabled />
          </FieldRow>

          {!!basePlan.add_ons?.length && (
            <Box mb='22px'>
              <Typography color={COLORS.black} fontWeight='medium' mb='4px'>
                Addons
              </Typography>
              <PlanExtensions extensions={basePlan.add_ons || []} />
            </Box>
          )}

          {!!basePlan.discounts?.length && (
            <Box mb='22px'>
              <Typography color={COLORS.black} fontWeight='medium' mb='4px'>
                Discounts
              </Typography>
              <PlanExtensions extensions={basePlan.discounts} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
