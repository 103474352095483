import { Box } from '@/components/Box/Box';
import { BillingPlanCardDetailsRow } from '../BillingPlanCardDetailsRow/BillingPlanCardDetailsRow';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { SwitchToggle } from '@/components/form/fields/SwitchToggle/SwitchToggle';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { formatAddonOrDiscountAmount, formatLimits } from '@/bundle/_Opco/_BillingPage/_BillingPlansPage/helpers';
import { BillingPlanAddonNameWrapper } from './styles';
import { SelectedAddonsType } from '../../BillingPlanCard';
import { BillingPlanType } from '@/types/billingTypes';

type BillingPlanCardDetailsType = {
  plan: BillingPlanType;
  selectedAddons?: SelectedAddonsType;
  onSelectAddon?: (id: string, checked: boolean) => void;
};

export const BillingPlanCardDetails = ({ plan, selectedAddons, onSelectAddon }: BillingPlanCardDetailsType) => {
  return (
    <Box display='flex' flexDirection='column' rowGap={8} mt='16px' mb='12px'>
      <BillingPlanCardDetailsRow>
        <strong>Share unlimited</strong> deposit info
      </BillingPlanCardDetailsRow>
      <BillingPlanCardDetailsRow>
        <strong>Collect</strong> or <strong>confirm</strong> wire info:{' '}
        <strong>{formatLimits(plan.wires_limit)}</strong>
        {!!plan.wires_limit && (
          <>
            <strong>x</strong>
            <Typography as='span' variant='bodySmall' color={COLORS.grey[650]}>
              /month
            </Typography>
          </>
        )}
      </BillingPlanCardDetailsRow>
      <BillingPlanCardDetailsRow>
        <strong>{formatLimits(plan.users_limit)}</strong> user(s)
      </BillingPlanCardDetailsRow>

      {plan.payment_service_plan_data.add_ons?.map((addon) => {
        return onSelectAddon ? (
          <SwitchToggle
            key={addon.id}
            label={
              <Box mr='16px'>
                <BillingPlanCardDetailsRow isActive={selectedAddons && selectedAddons[addon?.id]}>
                  <BillingPlanAddonNameWrapper>{addon.name}</BillingPlanAddonNameWrapper>
                  <strong> — {formatAddonOrDiscountAmount(addon)}</strong>
                </BillingPlanCardDetailsRow>
              </Box>
            }
            onChange={(event) => onSelectAddon?.(addon.id, event.target.checked)}
            checked={selectedAddons && selectedAddons[addon.id]}
            name=''
            size='small'
            hasErrorComponent={false}
          />
        ) : (
          <Box mr='16px' key={addon.id}>
            <BillingPlanCardDetailsRow>
              <BillingPlanAddonNameWrapper>{addon.name}</BillingPlanAddonNameWrapper>
              <strong> — {formatAddonOrDiscountAmount(addon)}</strong>
            </BillingPlanCardDetailsRow>
          </Box>
        );
      })}

      {!!plan.overage_price && (
        <Typography color={COLORS.grey[650]} variant='caption'>
          Overage rate — <strong>{convertCentsToDollars(plan.overage_price)}</strong> per collected or confirmed payment
          info
        </Typography>
      )}
    </Box>
  );
};
