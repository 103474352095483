import { DiscountAmount, Divider, TotalAmountWrapper } from './styles';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { COLORS } from '@/styles/colors';
import { formatAddonOrDiscountAmount } from '@/bundle/_Opco/_BillingPage/_BillingPlansPage/helpers';
import { BILLING_FREQUENCY_MAP } from '@/const/payment';
import { getBillingPlanTotalAddonsAmount } from './helpers';
import { SelectedAddonsType } from '../../BillingPlanCard';
import { BillingPlanType } from '@/types/billingTypes';

type BillingPlanCardTotalAmountType = {
  plan: BillingPlanType;
  selectedAddons: SelectedAddonsType;
};
export const BillingPlanCardTotalAmount = ({ plan, selectedAddons }: BillingPlanCardTotalAmountType) => {
  const planAmount = plan.payment_service_plan_data?.amount ?? 0;
  const discountAmount = plan.payment_service_plan_data?.total_discounts_amount ?? 0;

  const hasDiscount = discountAmount > 0;
  const planTotalAmount = hasDiscount ? planAmount - discountAmount : planAmount;
  const totalAmount =
    planTotalAmount + getBillingPlanTotalAddonsAmount(plan?.payment_service_plan_data, selectedAddons) ?? 0;

  return (
    <TotalAmountWrapper>
      <Box display='flex' flexDirection='column' rowGap={4} mb='12px'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography as='span' variant='bodySmall'>
            Plan Price
          </Typography>
          <Box display='flex' columnGap='8px'>
            {hasDiscount && <DiscountAmount>{convertCentsToDollars(planAmount)}</DiscountAmount>}
            <Typography as='span' variant='bodySmall' fontWeight='semibold'>
              {convertCentsToDollars(planTotalAmount)}
            </Typography>
          </Box>
        </Box>

        {plan.payment_service_plan_data.add_ons?.map((addon) => (
          <Box key={addon.id} display='flex' alignItems='center' justifyContent='space-between'>
            <Typography as='span' variant='bodySmall'>
              {addon.name}
            </Typography>
            <Typography as='span' variant='bodySmall' fontWeight='semibold'>
              {selectedAddons[addon.id] ? formatAddonOrDiscountAmount(addon) : '-'}
            </Typography>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box display='flex' alignItems='center' justifyContent='space-between' mt='8px'>
        <Box display='flex' flexDirection='column'>
          <Typography as='span' variant='bodySmall' fontWeight='semibold'>
            Total per month
          </Typography>
          <Typography as='span' variant='caption' color={COLORS.grey[650]} fontWeight='medium'>
            billed {BILLING_FREQUENCY_MAP[plan?.payment_service_plan_data?.billing_frequency]?.toLowerCase()}
          </Typography>
        </Box>
        <Box>
          <Typography as='span' variant='headline2' fontWeight='semibold'>
            {convertCentsToDollars(totalAmount)}
          </Typography>
        </Box>
      </Box>
    </TotalAmountWrapper>
  );
};
