import { BillingPlanPreviewWrapper } from '@/bundle/_Opco/_BillingPage/_BillingPlansPage/ui/BillingPlanPreview/styles';
import { Typography } from '@/components/Typography/Typography';
import { Box } from '@/components/Box/Box';
import { COLORS } from '@/styles/colors';
import { CloseButton } from '@/components/Drawer/styles';
import { useState } from 'react';
import { ReactComponent as PreviewIcon } from '../../../../../Auth/InviteUserFlow/SetPasswordPage/ui/images/previewIcon.svg';
import { BaseBillingPlanType, BillingPlanType } from '@/types/billingTypes';
import { BillingPlanCard } from '@/bundle/shared/components/BillingPlanCard/BillingPlanCard';
import { CreateBillingPlanFormValuesType } from '@/bundle/_Opco/_BillingPage/_BillingPlansPage/ui/CreateBillingPlanForm/const';
import { BILLING_PLAN_STATUS } from '@/const/billing';
import { convertDollarsToCents } from '@/helpers/formatHelpers';

type BillingPlanPreviewType = {
  basePlan: BaseBillingPlanType | null;
  planPreview: CreateBillingPlanFormValuesType;
};

const formatLimitsToNumber = (value: string) => {
  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return 0;
  }

  return numberValue;
};

export const BillingPlanPreview = ({ basePlan, planPreview }: BillingPlanPreviewType) => {
  const [isOpen, setIsOpen] = useState(true);
  const { name, description, hasOveragePrice, hasUsersLimit, hasWiresLimit, overage_price, users_limit, wires_limit } =
    planPreview || {};

  const planName = name || 'Preview Plan';
  const usersLimit = hasUsersLimit ? formatLimitsToNumber(users_limit) : null;
  const wiresLimit = hasWiresLimit ? formatLimitsToNumber(wires_limit) : null;
  const overagePrice = hasOveragePrice ? Number(convertDollarsToCents(overage_price)) : 0;

  const previewPlanCard: BillingPlanType = {
    id: basePlan?.id,
    payment_service_id: '',
    name: planName,
    description: description,
    users_limit: usersLimit,
    wires_limit: wiresLimit,
    overage_price: overagePrice,
    status: BILLING_PLAN_STATUS.ACTIVE,
    created_by: '',
    date_created: basePlan?.date_created,
    payment_service_plan_data: {
      ...basePlan,
      amount: basePlan?.amount || 0,
      total_add_ons_amount: basePlan?.total_add_ons_amount || 0,
      total_discounts_amount: basePlan?.total_discounts_amount || 0,
    },
  };

  const onClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <BillingPlanPreviewWrapper isOpen={isOpen}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        {isOpen && (
          <Box display='flex' flexDirection='column'>
            <Typography variant='headline4' fontWeight='semibold' color={COLORS.grey[650]}>
              Plan Preview
            </Typography>
          </Box>
        )}
        <CloseButton onClick={onClose}>
          <PreviewIcon />
        </CloseButton>
      </Box>
      {isOpen && (
        <Typography variant='bodySmall' color={COLORS.grey[650]} mt='4px'>
          Here’s how your plan will look like
        </Typography>
      )}
      {isOpen && (
        <Box mt='24px'>
          <BillingPlanCard plan={previewPlanCard} readonly />
        </Box>
      )}
    </BillingPlanPreviewWrapper>
  );
};
