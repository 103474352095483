import { BillingCardWrapper } from './styles';
import { Typography } from '@/components/Typography/Typography';
import { BillingPlanCardDetails } from './ui/BillingPlanCardDetails/BillingPlanCardDetails';
import { BillingPlanCardTotalAmount } from './ui/BillingPlanCardTotalAmount/BillingPlanCardTotalAmount';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { useEffect, useState } from 'react';
import { BillingPlanType } from '@/types/billingTypes';

export type SelectedAddonsType = { [id: string]: boolean };

type BillingPlanCardType = {
  plan: BillingPlanType;
  isSelectedPlan?: boolean;
  onSelectPlan?: (plan: BillingPlanType, addons: SelectedAddonsType) => void;
  selectedAddons?: SelectedAddonsType;
  readonly?: boolean;
};

export const BillingPlanCard = ({ plan, isSelectedPlan, onSelectPlan, readonly }: BillingPlanCardType) => {
  const [selectedAddons, setSelectedAddons] = useState<SelectedAddonsType>({});

  const toggleAddons = (id: string, checked: boolean) => {
    setSelectedAddons({
      ...selectedAddons,
      [id]: checked,
    });
  };

  useEffect(() => {
    if (!selectedAddons) {
      return;
    }

    setSelectedAddons(selectedAddons);
  }, [selectedAddons]);

  return (
    <BillingCardWrapper>
      <Typography fontWeight='semibold' variant='headline4'>
        {plan.name}
      </Typography>

      <Box display='flex' flexDirection='column' justifyContent='space-between' flexGrow={1}>
        <BillingPlanCardDetails plan={plan} selectedAddons={selectedAddons} onSelectAddon={toggleAddons} />
        <BillingPlanCardTotalAmount plan={plan} selectedAddons={selectedAddons} />
      </Box>

      <Box mt='auto'>
        <Button readonly={readonly} onClick={() => onSelectPlan?.(plan, selectedAddons)} disabled={isSelectedPlan}>
          {isSelectedPlan ? 'Current' : 'Select'}
        </Button>
      </Box>
    </BillingCardWrapper>
  );
};
