import styled from 'styled-components';
import { flex, getWidth } from '@/styles/layout';
import { body, bodySmall, poppinsMediumFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';

export type ButtonVariantType = 'primary' | 'secondary' | 'dark' | 'danger';

export const ButtonWrapper = styled.button<{
  width?: number | string;
  size: 'large' | 'medium';
  variant: ButtonVariantType;
  mobileStretch?: boolean;
  readonly?: boolean;
}>`
  position: relative;
  ${flex({ justifyContent: 'center', alignItems: 'center' })};
  ${poppinsMediumFont};
  ${body};
  text-align: center;
  padding: 13px 16px;
  border-style: none;
  border-radius: 10px;
  color: ${COLORS.white};
  background-color: ${COLORS.green[500]};
  width: ${({ width }) => getWidth(width)};
  height: 50px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: ${COLORS.green[450]};
  }

  &:disabled {
    color: ${COLORS.grey[600]};
    background-color: ${COLORS.grey[300]};
    cursor: not-allowed !important;
    border: none !important;
    pointer-events: none;
  }

  ${({ readonly }) =>
    readonly &&
    `
      pointer-events: none;
  `}

  ${media.mobile`
    ${({ mobileStretch }) =>
      mobileStretch &&
      `
      width: 100%;
    `};
  `}

  ${({ size }) =>
    size === 'medium' &&
    `
    height: 40px;
    ${bodySmall};
  `};

  ${({ variant }) =>
    variant === 'secondary' &&
    `
    color: ${COLORS.green[500]};
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.green[500]};

    &:hover {
      background: ${COLORS.mint[100]};
    }
  `};

  ${({ variant }) =>
    variant === 'dark' &&
    `
    background-color: ${COLORS.black};

    &:hover {
      background: ${COLORS.grey[850]};
    }
  `};

  ${({ variant }) =>
    variant === 'danger' &&
    `
    background-color: ${COLORS.red[400]};

    &:hover {
      background-color: ${COLORS.red[500]};
    }
  `};
`;

export const ButtonContent = styled.div<{ hasIcon: boolean }>`
  ${flex({ alignItems: 'center' })};
  justify-content: ${({ hasIcon }) => (hasIcon ? 'space-between' : 'center')};
  width: ${({ hasIcon }) => (hasIcon ? '100%' : 'auto')};

  ${media.mobile`
    justify-content: center;
    gap: 10px;
  `}
`;
