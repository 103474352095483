import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { poppinsMediumFont, subTitle } from '@/styles/typography';
import { ellipsis } from '@/styles/common';
import { COLORS } from '@/styles/colors';

export const WireHeading = styled.div`
  ${flex({ alignItems: 'center' })};
  column-gap: 8px;

  ${media.mobile`
    ${flex({ justifyContent: 'start' })};
  `}
`;

export const WireHeadingTitle = styled.div`
  ${poppinsMediumFont};
  ${subTitle};
  ${ellipsis};
  color: ${COLORS.black};
`;
