import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { Button } from '@/components/Button/Button';
import { Drawer } from '@/components/Drawer/Drawer';
import { ReactComponent as PlusIcon } from '@/images/PlusIcon.svg';
import { useState } from 'react';
import { BillingPlansTable } from './ui/BillingPlansTable/BillingPlansTable';
import { CreateBillingPlanForm } from './ui/CreateBillingPlanForm/CreateBillingPlanForm';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadBillingPlans } from './api';
import { BillingPlanPreview } from '@/bundle/_Opco/_BillingPage/_BillingPlansPage/ui/BillingPlanPreview/BillingPlanPreview';
import { BaseBillingPlanType } from '@/types/billingTypes';
import { CreateBillingPlanFormValuesType } from './ui/CreateBillingPlanForm/const';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';

const LOAD_BILLING_PLANS_PARAMS = {
  limit: 1000,
  page: 0,
};

export const BillingPlansPage = () => {
  const queryClient = useQueryClient();
  const searchParams = useGetSearchParams(LOAD_BILLING_PLANS_PARAMS);

  const [isOpenCreatePlan, setIsOpenCreatePlan] = useState(false);
  const [previewPlan, setPreviewPlan] = useState<CreateBillingPlanFormValuesType | null>(null);
  const [selectedBasePlan, setSelectedBasePlan] = useState<BaseBillingPlanType | null>(null);

  const { data } = useQuery({
    queryKey: ['load_billing_plans', searchParams],
    queryFn: () => loadBillingPlans(searchParams),
    placeholderData: keepPreviousData,
  });

  const openCreatePlan = () => {
    setIsOpenCreatePlan(true);
  };

  const refetchBillingPlans = () => {
    queryClient.invalidateQueries({ queryKey: ['load_billing_plans', searchParams] });
  };

  const billingPlans = data?.body?.results;

  return (
    <ContentLayout
      title='Plans'
      headerAction={
        <Button width={160} mobileStretch icon={<PlusIcon />} iconPosition='left' onClick={openCreatePlan}>
          Create Plan
        </Button>
      }
      subtitle='View the plans created in the WireVault.'
      offsetSize='small'
    >
      <BillingPlansTable plans={billingPlans} />

      <Drawer
        isOpen={isOpenCreatePlan}
        header='Create Plan'
        subHeader='Create a plan to bill your clients.'
        onClose={() => setIsOpenCreatePlan(false)}
        externalBlock={<BillingPlanPreview planPreview={previewPlan} basePlan={selectedBasePlan} />}
      >
        <CreateBillingPlanForm
          billingPlans={billingPlans}
          basePlan={selectedBasePlan}
          onSelectBasePlan={setSelectedBasePlan}
          onChangePlan={setPreviewPlan}
          onSuccess={refetchBillingPlans}
          onClose={() => setIsOpenCreatePlan(false)}
        />
      </Drawer>
    </ContentLayout>
  );
};
